import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { FirebaseService } from '../services/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard  {
  constructor (
    private router: Router,
    private session: SessionService,
    private firebase: FirebaseService
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

      // TODO implement refresh token
      await this.session.retrieveFromStorage();
      this.firebase.updateUserId();
      if (this.session.isAuthenticated()) {
        return true;
      } else {
        this.router.navigateByUrl('/login');
      }
  }
  
}
