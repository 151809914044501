<div class="footer">
  <div class="footer-top"></div>
  <div class="footer-main">
    <div class="wrapper wrapper-footer">
      <div class="wrapper-footer-content">
        <div class="wrapper-footer-content-left">
          <img
            class="footer-logo"
            src="/assets/img/CarlileConnect_LOGOwhite.png"
            alt=""
          />
        </div>
        <div class="wrapper-footer-content-center">
          <div class="wrapper-footer-main-contacts">
            <div class="contact-box">
              <span class="footer-main-contact tel"
                ><ion-icon name="call"></ion-icon>1.800.478.1853</span
              >
              <span class="footer-main-contact"
                ><ion-icon name="mail"></ion-icon
                >customercare@carlileconnect.com</span
              >
            </div>
          </div>
        </div>
        <div class="wrapper-footer-content-right">
          <div class="wrapper-footer-main-right-social">
            <a
              href="https://www.facebook.com/CarlileTransportation"
              target="_blank"
            >
              <svg
                id="Bold"
                height="512"
                viewBox="-2 0 30 24"
                width="512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
