import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReusableHeaderComponent } from './reusable-header/reusable-header.component';
import { ReusableFooterComponent } from './reusable-footer/reusable-footer.component';
import { MenuPageRoutingModule } from '../pages/menu/menu-routing.module';

@NgModule({
  imports: [CommonModule, IonicModule, MenuPageRoutingModule],
  declarations: [ReusableHeaderComponent, ReusableFooterComponent],
  exports: [ReusableHeaderComponent, ReusableFooterComponent]
})

export class ComponentsModule{}
