import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { Platform } from '@ionic/angular';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { environment } from 'src/environments/environment'
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit, OnDestroy {

  selectedPath = '';

  subscriptions: any = [] // keep track of subscriptions so we can unsubscribe on destroy

  // these two variables updated on route change
  isMenuDisabled = false;

  appInfo = {
    name: null,
    versionNumber: null
  };

  endpointString: string;

  pages = [
    {
      title: 'Ship to Alaska',
      url: '/quote',
      icon: 'boat-outline'
    },
    {
      title: 'Track a shipment',
      url: '/track',
      icon: 'locate-outline'
    },
    {
      title: 'Pay invoice',
      url: '/invoices',
      icon: 'card-outline',
      requiredScope: 'customer:invoices'
    },
    {
      title: 'My account',
      url: '/account',
      icon: 'person-circle-outline'
    },
    {
      title: 'Logout',
      url: '/login',
      icon: 'lock-closed',
    }
  ];

  environmentFile = environment;

  constructor(
    private router: Router,
    private session: SessionService,
    private platform: Platform,
    private appVersion: AppVersion,
    private api: ApiService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof RouterEvent) {
        this.selectedPath = event.url;
      }
    })
  }

  ngOnInit() {
    this.updateAppInfo();

    this.updatePickupLocations();

    this.subscriptions.push(this.router.events.subscribe(
      (event) => {
        if (event instanceof RouterEvent) {
          if (event.url) {
            if (event.url.indexOf("stops") > 0) {
              // swipe is enabled if not on summary-customer or signature pages
              this.isMenuDisabled = (event.url.indexOf("customer") > 0 || event.url.indexOf('signature') > 0);
            } else {
              this.isMenuDisabled = false;
            }
            this.selectedPath = event.url;
          }
        }
      }
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => {
        subscription.unsubscribe();
      }
    );
  }

  scopedPages() {
    return this.pages.filter((page) => this.session.isScoped(page['requiredScope']));
  }

  /**
   * TODO: move this caching to api.service.ts - would be cleaner
   * check for cached locations, if none then load them from API
   */
  async updatePickupLocations() {
    const locations = await this.session.getPickupLocations();
    console.log("Pickup locations: ", locations);
    if (!locations) {
      this.api.getLocations().subscribe((pickupLocations: any[]) => {
        console.log("Cached locations unavailable - retrieved fromm API:", pickupLocations);
        this.session.setPickupLocations(pickupLocations);
      })
    }
  }

  async updateAppInfo() {
    if (this.platform.is('cordova')) {
      this.appInfo.name = await this.appVersion.getAppName();
      this.appInfo.versionNumber = await this.appVersion.getVersionNumber();
    }
    this.endpointString = (this.session.environment || 'PROD').toUpperCase()
  }

  showVersionInfo() {
    return this.platform.is('cordova');
  }
}
