import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { tap, catchError, retryWhen } from 'rxjs/internal/operators';
import { throwError, from, Observable } from 'rxjs';
import { SessionService } from './session.service';
import { NetworkService, ConnectionStatus } from './network.service';
import { NavController, ToastController } from '@ionic/angular';
import { FirebaseService } from 'src/app/services/firebase.service';

import * as moment from 'moment';
import { Order } from '../models/order.model';
import { User } from '../models/user.model';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  UNAUTHENTICATED_ENVIRONMENT = ''; // TODO

  constructor(
    private http: HttpClient,
    private session: SessionService,
    private networkService: NetworkService,
    private toastController: ToastController,
    private firebase: FirebaseService,
    private navController: NavController,
  ) { }

  baseUrl(environment = this.session.environment || this.UNAUTHENTICATED_ENVIRONMENT) {
    let environmentSuffix: string =
      (!!environment && environment.length > 0)
        ? `-${environment}`
        : '';
    return `https://api${environmentSuffix}.carlileconnect.com`;
  }

  login(username, password, environment) {
    return this.http.post(`${this.baseUrl(environment)}/api/token`,
      `grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
      this.httpOptionsForLogin
    ).pipe(
      tap(
        data => {
          this.session.saveCredentials(
            data['access_token'],
            data['.expires'],
            data['refresh_token'],
            data['scopes'].split(','),
            username,
            environment
            );
        },
        error => console.log("ERROR", error)
      )
    )
  }

  logout() {
    return from(this.session.clearSession());
    // TODO make API call to invalidate tokens server-side too
  }

  request(method: string, url: string, data: any) {
    return this.http.request(method, url, { body: data, headers: this.getAuthHeaders() });
  }

  rate(order) {
    return this.http.request('POST', `${this.baseUrl()}/api/quote/b2c`, { body: order, headers: this.getAuthHeaders() });
  }

  /**
   * gets profile information of currently logged in user
   */
  getCurrentUser(): Observable<User> {
    return this.http.get(`${this.baseUrl()}/api/users/me`, this.httpOptions).pipe(
      tap({
        next: (res: User) => {
          this.session.setCurrentStoredUser(res);
        },
        error: error => {
          console.warn("Error getting current user. Session Service will not be updated.", error);
          this.navController.navigateRoot(['/login']);
          this.toastController.create({
            message: `Something went wrong fetching your account details. Please try again later.`,
            duration: 3000,
            color: 'danger',
            position: 'bottom'
          }).then(toast => toast?.present());
        }
      })
    )
  }

  /**
   * updates currently-logged in user's information
   */
  putCurrentUser(user: User) {
    return this.http.put(`${this.baseUrl()}/api/users/me`, user, this.httpOptions).pipe(
      tap({
        next: (res: User) => {
          this.session.setCurrentStoredUser(res);
        },
        error: error => {
          console.warn("Error getting current user. Session Service will not be updated.", error);
        }
      })
    )
  }

  validateDiscount(discountCode) {
    return this.http.get(`${this.baseUrl()}/api/discountcodes/${discountCode}/validate`, this.httpOptions)
  }
  getEmail(email){
    return this.http.get(`${this.baseUrl()}/api/users/exists?emailAddress=${email}`, this.httpOptions)
  }

  postUser(user: User, environment: string) {
    return this.http.post(`${this.baseUrl(environment)}/api/users`, user);
  }

  postNewPassword(email){
    return this.http.post(`${this.baseUrl()}/api/users/password/reset`, email, this.httpOptions);
  }

  getInvoices(fromDate, toDate, startRow) {
    // return this.http.get('../../assets/data/invoices.mock.json', this.httpOptions);
    return this.http.get(`${this.baseUrl()}/api/invoices/grid?fromDate=${fromDate}&toDate=${toDate}&startRow=${startRow}`, this.httpOptions);
  }

  getLocations() {
    return this.http.get(`${this.baseUrl()}/api/locations/`);
  }

  getDiscountCodes() {
    return this.http.get(`${this.baseUrl()}/api/discountcodes/`, this.httpOptions);
  }
  postDiscountCode(code){
    return this.http.post(`${this.baseUrl()}/api/discountcodes/`, code,  this.httpOptions);
  }
  editDiscountCode(code, codeId: number){
    return this.http.put(`${this.baseUrl()}/api/discountcodes/${codeId}`, code,  this.httpOptions);
  }
  deleteDiscountCode(codeId){
    return this.http.delete(`${this.baseUrl()}/api/discountcodes/${codeId}`, this.httpOptions);
  }

  getCurrentOrders() {
    return this.http.get(`${this.baseUrl()}/api/orders/consumer/current/`, this.httpOptions)
  }

  patchOrder(orderNumber: string, order) {
    return this.http.patch(`${this.baseUrl()}/api/orders/${orderNumber}`, order, this.httpOptions);
  }

  patchPassword(hashPassword){
    return this.http.patch(`${this.baseUrl()}/api/users/password`, hashPassword, this.httpOptions);
  }

  postOrder(order) {
    return this.http.post(`${this.baseUrl()}/api/orders/`, order, this.httpOptions);
  }

  postPayment(paymentPayload) {
    return this.http.post(`${this.baseUrl()}/api/payment/`, paymentPayload, this.httpOptions);
  }

  extractErrors(response) {
    return response['error']['errors'].map((error) => error['errorMessage']);
  }

  private get httpOptions() {
    return {
      headers: this.getAuthHeaders()
    };
  }

  getAuthHeaders() {
    return new HttpHeaders({
      'Authorization': `Bearer ${this.session.accessToken}`
    })
  }

  private get httpOptionsForLogin() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // Back-end error
      console.error(`HTTP error ${error.status}, body: ${error.error}`);

      if (error.status == 401) {
        return throwError('Invalid username or password. Please try again, or contact Carlile helpdesk for assistance.');
      } else {
        return throwError(`An error occurred while logging in. Please try again, or contact Carlile helpdesk for assistance.\n\n[Error code: ${error.status} ${error.statusText}]`);
      }
    }
  }
}
