import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { FirebaseService } from '../services/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {
  constructor (
    private router: Router,
    private session: SessionService,
    private firebase: FirebaseService
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

      // TODO implement refresh token
      await this.session.retrieveFromStorage();

      if (this.session.isScoped("admin")) {
        console.log("User is admin.");
        return true;
      } else {
        console.log("User is NOT admin.");
        this.router.navigateByUrl('/'); // redirect to base route (whatever that is) if not admin
      }
  }
  
}
