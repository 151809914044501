<ion-header class="header-desktop">
  <ion-toolbar>
    <div class="header-top">
      <div class="wrapper wrapper-header-top">
        <div class="header-number">
          <span>MyConnect Customer #: {{ customerNumber }}</span>
        </div>
      <div class="header-phone">
        <ion-icon class="header-top-icon" name="call"></ion-icon>
        <span>1.800.478.1853</span>
      </div>
    </div>
    </div>
    <div class="header-main">
      <div class="wrapper wrapper-header-main">
        <img class="header-main-logo" src="/assets/img/CarlileConnect_LOGO.png" alt="LOGO">
        <ul class="header-main-list">
          <a routerLink="/quote" class="header-main-item" >Ship</a>
          <a [routerLink]="['/track']" [queryParams]="{filter: 'unpaid'}" class="header-main-item" >Pay</a>
          <a routerLink="/track" class="header-main-item" >Track</a>
        </ul>
        <button class="header-main-item admin" *ngIf="isUserAdmin" fill="clear" routerLink="/admin">Admin</button>
        <div class="header-main-buttons" (click)='openPopover($event)'>
          <ion-icon color="primary" class="icon" name="person-circle"></ion-icon>
          <span class="header-main-first-name" color="primary">{{ firstName }}</span>
          <ion-icon color="primary" name="caret-down"></ion-icon>
          <!-- <button class="header-main-button" routerLink="/login"><ion-icon name="arrow-forward"></ion-icon>Sign Out</button> -->
        </div>
      </div>
    </div>
  </ion-toolbar>
</ion-header>

<ion-header class="header-mobile">
  <ion-toolbar >
    <div class="header-mobile-content">
      <div class="header-mobile-menu">
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <h4 class="header-mobile-greeting">Hi, {{ firstName }}</h4>
      </div>
      <img class="header-mobile-logo" src="/assets/img/rsz_1carlileconnect_logo.png" alt="LOGO">
    </div>
  </ion-toolbar>
</ion-header>
