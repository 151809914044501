import { Component } from '@angular/core';

import{Router, NavigationEnd} from '@angular/router';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { ConnectionStatus, NetworkService } from './services/network.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {
  
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private networkService: NetworkService,
    public router: Router
  ) {
    this.initializeApp();
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
          gtag('config', 'UA-186015946-1', 
                {
                  'page_path': event.urlAfterRedirects
                }
              );
      }
    })
  } 

  initializeApp() {
    this.platform.ready().then(() => {
      this.splashScreen.hide();

      this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
        if (status == ConnectionStatus.Online) {
          // this.offlineManager.checkForEvents();
        }
      });
    });
  }
}
