<ion-menu contentId="content" [disabled]="isMenuDisabled">
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title>Carlile Transportation</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list>
      <ion-menu-toggle auto-hide="false" *ngFor="let p of scopedPages()">
        <ion-item [routerLink]="p.url" routerDirection="root" [class.active-item]="selectedPath === p.url">
          <ion-icon [name]="p.icon" slot="start"></ion-icon>
          <ion-label>
            {{ p.title }}
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
    </ion-list>
  </ion-content>
  <ion-footer no-border>
    <p class="version-info-container">
      <span class="version-number-tag">
        {{ appInfo.name }}&nbsp;{{ appInfo.versionNumber }}
      </span>
      <span>&nbsp;&bull;&nbsp;<span [ngClass]="{'red-endpoint-text' : endpointString !== 'PROD'}">{{ endpointString }}</span></span>
    </p>
  </ion-footer>
</ion-menu>
<ion-router-outlet id="content"></ion-router-outlet>
