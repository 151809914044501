import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';

const STORAGE_KEY_AUTH_ACCESS_TOKEN = "auth.accessToken";
const STORAGE_KEY_AUTH_ACCESS_TOKEN_EXPIRY = "auth.accessTokenExpiry";
const STORAGE_KEY_AUTH_REFRESH_TOKEN = 'auth.refreshToken'
const STORAGE_KEY_AUTH_SCOPES = 'auth.scopes'
const STORAGE_KEY_AUTH_USER_ID = 'auth.userId';
const STORAGE_KEY_AUTH_ENVIRONMENT = 'auth.environment';
const STORAGE_KEY_AUTH_CURRENT_USER = 'auth.currentUser';
const STORAGE_KEY_APP_PICKUP_LOCATIONS = 'app.pickupLocations';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private _accessToken: string;
  private _accessTokenExpiry: Date;
  private _refreshToken: string;
  private _scopes: string[];
  private _storage: Storage | null = null;

  userId: string;
  environment: string;

  currentUser: User;

  constructor(private storage: Storage) { 
    this.init();
  }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  isAuthenticated(scope = null) {
    if (!this._accessToken) {
      console.log('No access token.');
      return false;
    } else if (this.isTokenExpired()) {
      console.log('Access token has expired.');
      return false;
    } else if (!this.isScoped(scope)) {
      console.log(`User does not have access to scope ${scope}.`);
      return false;
    }

    console.log('User has previous session still active.');
    return true;
  }

  // Convenience method that skips token validation steps already checked by guard
  isScoped(scope) {
    return !scope || this._scopes.includes(scope)
  }


  isTokenExpired(): boolean {
    var now = new Date();
    return this._accessTokenExpiry < now;
  }

  get accessToken() {
    return this._accessToken;
  }

  async retrieveFromStorage() {
    this._accessToken = await this._storage.get(STORAGE_KEY_AUTH_ACCESS_TOKEN);
    this._accessTokenExpiry = await this._storage.get(STORAGE_KEY_AUTH_ACCESS_TOKEN_EXPIRY);
    this._refreshToken = await this._storage.get(STORAGE_KEY_AUTH_REFRESH_TOKEN);
    this._scopes = await this._storage.get(STORAGE_KEY_AUTH_SCOPES);
    this.userId = await this._storage.get(STORAGE_KEY_AUTH_USER_ID);
    this.environment = await this._storage.get(STORAGE_KEY_AUTH_ENVIRONMENT);
  }

  saveCredentials(accessToken, accessTokenExpiry, refreshToken, scopes, username, environment) {
    this._storage.set(STORAGE_KEY_AUTH_ACCESS_TOKEN, accessToken);
    this._storage.set(STORAGE_KEY_AUTH_ACCESS_TOKEN_EXPIRY, accessTokenExpiry);
    this._storage.set(STORAGE_KEY_AUTH_REFRESH_TOKEN, refreshToken);
    this._storage.set(STORAGE_KEY_AUTH_SCOPES, scopes);
    this._storage.set(STORAGE_KEY_AUTH_USER_ID, username);
    this._storage.set(STORAGE_KEY_AUTH_ENVIRONMENT, environment);
    this.retrieveFromStorage();
  }


  setCurrentStoredUser(currentUser: User) {
    this._storage.set(STORAGE_KEY_AUTH_CURRENT_USER, currentUser);
    this.getCurrentStoredUser;
  }

  async getCurrentStoredUser() {
    this.currentUser = (await this._storage.get(STORAGE_KEY_AUTH_CURRENT_USER));
    return this.currentUser;
  }


  setPickupLocations(locations: any[]) {
    this._storage.set(STORAGE_KEY_APP_PICKUP_LOCATIONS, locations);
  }

  getPickupLocations() {
    return this._storage.get(STORAGE_KEY_APP_PICKUP_LOCATIONS);
  }

  async clearSession() {
    // await this._storage.remove('auth.environment');
    await this._storage.clear();
    this.retrieveFromStorage();
  }

}
