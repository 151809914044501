import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { SessionService } from 'src/app/services/session.service';
import { PopoverComponent } from '../popover/popover.component';

@Component({
  selector: 'app-reusable-header',
  templateUrl: './reusable-header.component.html',
  styleUrls: ['./reusable-header.component.scss'],
})
export class ReusableHeaderComponent implements OnInit {
  firstName: string;
  customerNumber: string;

  isUserAdmin: boolean = false;

  constructor(private api: ApiService, public popover: PopoverController, private session: SessionService) { }

  ngOnInit() {
    this.api.getCurrentUser().subscribe(
      (res: any) => {
        console.log("Current user:  ", res);
        this.firstName = res.firstName;
        this.customerNumber = res.customerNumber;
      }
    );
    this.session.retrieveFromStorage().then(() => {
      this.isUserAdmin = this.session.isScoped("admin");
    })
  }
  async openPopover(ev) {
    const popover = await this.popover.create({
      component: PopoverComponent,
      event: ev,
      cssClass: 'customPopover',
      translucent: true
    });
    return await popover.present();
  }
}
