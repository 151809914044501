import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  customerNumber: string;

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.api.getCurrentUser().subscribe(
      (res: any) => {
        this.customerNumber = res.customerNumber;
      }
    );
  }

}
