import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { MenuPage } from './pages/menu/menu.page';
import { MenuPageModule } from './pages/menu/menu.module';
import { InvoicesResolverService } from './resolvers/invoices-resolver.service';
import { LocationsResolver } from './resolvers/locations-resolver';
import { AdminGuard } from './guards/admin.guard';

const routes: Routes = [
  { path: '', redirectTo: 'track', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    component: MenuPage,
    canActivate: [ AuthenticatedGuard ],
    children: [
      {
        path: 'quote',
        loadChildren: () => import('./pages/quote/quote.module').then( m => m.QuotePageModule),
        resolve: {
          locations: LocationsResolver
        }
      },
      {
        path: 'track',
        loadChildren: () => import('./pages/track/track.module').then( m => m.TrackPageModule),
      },    
      {
        path: 'account',
        loadChildren: () => import('./pages/userinfo/userinfo.module').then( m => m.UserinfoPageModule)
      },
      {
        path: 'pay/:token',
        loadChildren: () => import('./pages/pay/pay.module').then( m => m.PayPageModule)
      },
      {
        path: 'admin',
        canActivate: [ AdminGuard ],
        loadChildren: () => import('./pages/admin/admin.module').then( m => m.AdminPageModule)
      },
    ]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule),
    resolve: {
      locations: LocationsResolver
    }
  },
  {
    path: 'quote/inline',
    loadChildren: () => import('./pages/inline-quote/inline-quote.module').then( m => m.InlineQuotePageModule),
    resolve: {
      locations: LocationsResolver
    },
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    MenuPageModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
