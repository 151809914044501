import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { FirebaseCrashlytics } from '@ionic-native/firebase-crashlytics/ngx';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private platform: Platform,
    private session: SessionService,
    private analytics: FirebaseAnalytics,
    private crashlytics: FirebaseCrashlytics
  ) {
    if (this.platform.is('cordova')) {
      console.log("FirebaseService is available on cordova.");
      this.initializeCrashlytics();
    }
  }

  crashlyticsInstance = null;
  
  initializeCrashlytics() {
    if (this.platform.is('cordova')) {
      this.crashlyticsInstance = this.crashlytics.initialise();
      console.log("FirebaseService: CrashLytics enabled");
    }
  }

  testCrashLog() {
    if (this.platform.is('cordova')) {
      this.crashlyticsInstance.logException("Testing Crashlytics...");
    }
  }

  /**
   * send identifying information of the driver currently using the
   * app as both UserId (officially supported) and as a custom DriverId
   * user property (workaround for lack of BigQuery integration)
   */
  updateUserId() {
    if (this.platform.is('cordova')) {
      var id = this.session.userId;
      if (id) {
        this.analytics.setUserId(id);
        this.analytics.setUserProperty("DriverId", id);
      } else {
        console.warn("Couldn't set Firebase Analytics user id: ", id);
      }
    }
  }

  /**
   * Sends an arbitrarily named event with payload to Firebase Analytics
   * @param name - (custom) name for event 
   * @param params - payload for logged event 
   */
  logEvent(name: string, params: object) {
    if (this.platform.is('cordova')) {
      this.analytics.logEvent(name, params)
      .then((res: any) => { console.log("Firebase response:" + res); })
      .catch((error: any) => console.error("Firebase error.", error));
    }
  }

  /**
   * Sends a screen_view event to Firebase Analytics
   * @param name - translates to firebase_screen param in the Firebase console
   */

  trackScreen(name: string) {
    if (this.platform.is('cordova')) {
      this.analytics.setCurrentScreen(name)
        .then((res: any) => { console.log("View successfully tracked. Firebase response: " + res); })
        .catch(error => console.log('Error tracking view. ', error));
    }
  }
}
