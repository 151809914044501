import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { ApiService } from '../services/api.service';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class LocationsResolver  {

  constructor(
    private apiService: ApiService,
    private sessionService: SessionService,
  ) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) { // : Observable<any> | Promise<any> | any {
    console.log('LocationsResolver: checking session service');
    let locations = await this.sessionService.getPickupLocations();
    if (!locations) {
      locations = await this.apiService.getLocations().toPromise();
      await this.sessionService.setPickupLocations(locations);
    }

    // TODO Hack for now to ensure user data is retrieved -- should decide a better pattern going forward
    let currentUser = await (this.sessionService.getCurrentStoredUser());
    if (this.sessionService.isAuthenticated() && !currentUser) {
      currentUser = await this.apiService.getCurrentUser().toPromise();
      await this.sessionService.setCurrentStoredUser(currentUser);
    }

    return locations;
  }
}
